/* moving-sentence.css */

.news-container {
  font-family: 'Roboto', sans-serif;
  background-color: darkblue;
  width: 80%;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.news-container h2 {
  color: white;
  margin-bottom: 10px;
  font-family: 'Roboto', sans-serif;
}

.news-container button {
  background: transparent;
  color: white;
  border-radius: 5px;
  box-shadow: none;
  border: none;
  transition: transform 0.3s ease;
  transform: scale(1);
}

.news-container button:hover {
  transform: scale(1.1); /* Zoom in on hover */
}

@media only screen and (max-width: 768px) {
  .news-container h2 {
    margin-left: 1em; /* Adjust the left margin for mobile view */
  }

  .news-container button {
    margin-right: 1em; /* Adjust the right margin for mobile view */
  }
}

/* Desktop styles */
@media only screen and (min-width: 769px) {
  .news-container h2 {
    margin-left: 4em; /* Adjust the left margin for desktop view */
  }

  .news-container button {
    margin-right: 3em; /* Adjust the right margin for desktop view */
  }
}

.scrolling-text {
  overflow: hidden;
  height: 200px; /* Set a fixed height to control the visible area */
}

.scrolling-text ul {
  list-style: none;
  margin: 0;
  padding: 0;
  color: black; /* Set text color for better visibility */
  animation: scroll 15s infinite linear; /* Adjust the animation duration */
}

.scrolling-text li {
  padding: 10px 24px;
  color: 'black';
  margin-bottom: 10px; /* Add space between news items */
}

/* Pause animation on hover */
.scrolling-text:hover ul {
  animation-play-state: paused;
}
