@tailwind base;
@tailwind components;
@tailwind utilities;

/* Add this to ensure Tailwind loads after MUI */
:root {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
}

.animate-float-in {
  will-change: transform, opacity;
  animation: floatIn 1s ease-out forwards;
}

.animate-slide-up {
  will-change: transform, opacity;
  animation: slideUp 0.8s ease-out forwards;
}

@keyframes scroll {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
body {
  background-image: url('./backgroundbapuji.webp'); 
  background-size: cover; 
  background-repeat: no-repeat; 
  background-attachment: fixed; 
  background-position: center; 
  margin: 0; 
  padding: 0; 
}

/* @keyframes floatIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideUp {
  0% {
    opacity: 0;
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
} */
